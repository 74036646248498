import React from "react"
import OnlineCourses from "../allcourses/OnlineCourses"
import Heading from "../common/heading/Heading"
import "../allcourses/courses.css"
import { coursesCard } from "../../dummydata"
import Services from "../Services/Services"
import Projects from "../Projects/Project"


const HAbout = () => {
  return (
    <>
      <section className='homeAbout'>

        <Projects />


      </section>
    </>
  )
}

export default HAbout


export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Online Courses",
    desc: "Explore a vast selection of online courses designed to help you gain new skills and knowledge from the comfort of your home.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn A Certificate",
    desc: "Complete our courses and earn certificates that validate your skills and boost your career prospects.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Learn with Experts",
    desc: "Gain insights and guidance from industry experts who will help you navigate your learning journey effectively.",
  },
  {
    id: 4,
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    title: "100+ Interns",
    desc: "Join a vibrant community of interns, each bringing their unique talents and ideas to collaborative projects and learning experiences.",
  },
];



export const awrapper = [
  {

    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "100+",
    title: "INTERNS",
  },

  // {
  //   cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
  //   data: "100",
  //   title: "TRUSTED TUTORS",
  // },

  // {
  //   cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
  //   data: "587",
  //   title: "COURSES",
  // },
]
export const coursesCard = [
  // {
  //   id: 1,
  //   cover: "../images/courses/c1.png",
  //   coursesName: "Introducing to Software Engineering",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by John Smith",
  //       totalTime: "50 lectures (190 hrs)",
  //     },
  //   ],
  //   priceAll: "$100 All Course",
  //   pricePer: "$15 per month",
  // },
  // {
  //   id: 2,
  //   cover: "../images/courses/c2.png",
  //   coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Ram Gurung",
  //       totalTime: "30 lectures (125 hrs)",
  //     },
  //   ],
  //   priceAll: "$200 All Course",
  //   pricePer: "$25 per month",
  // },
  // {
  //   id: 3,
  //   cover: "../images/courses/c3.png",
  //   coursesName: "HTML, CSS, and Javascript for Web Developers",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Saroj Nepal",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$50 All Course",
  //   pricePer: "$5 per month",
  // },
  // {
  //   id: 4,
  //   cover: "../images/courses/c4.png",
  //   coursesName: "Introducing to Programming with WordPress",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Prabin Thapa Mager",
  //       totalTime: "20 lectures (20 hrs)",
  //     },
  //   ],
  //   priceAll: "$30 All Course",
  //   pricePer: "$3 per month",
  // },
  // {
  //   id: 5,
  //   cover: "../images/courses/c5.png",
  //   coursesName: "Introducing to Programming with ReactJS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "100 lectures (150 hrs)",
  //     },
  //   ],
  //   priceAll: "$300 All Course",
  //   pricePer: "$30 per month",
  // },
  // {
  //   id: 6,
  //   cover: "../images/courses/c6.png",
  //   coursesName: "Learn Frontend Programming Language",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "200 lectures (300 hrs)",
  //     },
  //   ],
  //   priceAll: "$500 All Course",
  //   pricePer: "$80 per month",
  // },
  // {
  //   id: 7,
  //   cover: "../images/courses/c7.png",
  //   coursesName: "Introducing to with HTML / CSS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Price",
  //       totalTime: "20 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$2 per month",
  // },
  // {
  //   id: 8,
  //   cover: "../images/courses/c8.png",
  //   coursesName: "Introducing to with JAVA",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "80 lectures (200 hrs)",
  //     },
  //   ],
  //   priceAll: "$200 All Course",
  //   pricePer: "$40 per month",
  // },
  // {
  //   id: 9,
  //   cover: "../images/courses/c9.png",
  //   coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$3 per month",
  // },
]
export const online = [
  {
    id: 1,
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "Ui/Ux Design",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "designing"
  },
  {
    id: 2,
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Graphic designing",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "designing"
  },
  {
    id: 3,
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Web Development",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "computer"
  },
  {
    id: 4,
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Full Stack Development",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "computer"
  },
  {
    id: 5,
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Machine Learning",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "aiml"
  },
  {
    id: 6,
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Data Science",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "aiml"
  },
  {
    id: 7,
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "C Programming",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "computer"
  },
  {
    id: 8,
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Social Media Marketing",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "computer"
  },
  {
    id: 9,
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Python Development",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "aiml"
  },
  {
    id: 10,
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Java Development",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "computer"
  },
  {
    id: 11,
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Data Analysis",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "aiml"
  },
  {
    id: 12,
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Power BI",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "aiml"
  },
  {
    id: 13,
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Autocad",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "civil"
  },
  {
    id: 14,
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Staad pro",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "civil"
  },
  {
    id: 15,
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Revit Structure",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "civil"
  },
  {
    id: 16,
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "SolidWorks",
    course: "Internship",
    buttonName: "Apply Now",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScvb-pxBomubgdOEWGKA8cQCpE-eVNz18pQJInXtQSNdbK0iw/viewform?usp=sf_link",
    category: "mech"
  }
];

export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const process = [
  {
    step: 1,
    title: "Registration",
    desc: "Sign up on our platform to start your journey with SkillUpward.",
  },
  {
    step: 2,
    title: "Get Offer Letter",
    desc: "Receive your offer letter after a successful registration and initial screening.",
  },
  {
    step: 3,
    title: "Complete the Given Tasks",
    desc: "Work on the tasks assigned to you as part of the virtual internship program.",
  },
  {
    step: 4,
    title: "Submit Completed Tasks",
    desc: "Submit your completed tasks for review by your mentors.",
  },
  {
    step: 5,
    title: "Get Certificates",
    desc: "Receive your certificates upon successful completion of all tasks.",
  },
];

export const faq = [
  {
    title: "What is SkillUpward?",
    desc: "SkillUpward is a platform dedicated to bridging the gap between academic learning and professional experience by offering meaningful internships. We provide students with hands-on opportunities to enhance their skills and prepare for the competitive job market."
  },
  {
    title: "What types of internships do you offer?",
    desc: "We offer a diverse range of internship opportunities in various domains, including Full-Stack Web Development, Data Science, Digital Marketing, and more. Each internship is designed to provide practical experience and valuable insights relevant to the field."
  },
  {
    title: "How does SkillUpward support its interns?",
    desc: "Interns at SkillUpward receive personalized mentorship from experienced professionals who provide guidance and support throughout their internship. This mentorship helps interns navigate their career paths and develop the skills needed for their future careers."
  },
  {
    title: "What kind of experience will I gain during an internship at SkillUpward?",
    desc: "Interns work on real projects that offer practical experience and skills that are highly valued by employers. You will gain hands-on experience, learn industry best practices, and develop a strong foundation for your career."
  },
  {
    title: "How does SkillUpward collaborate with educational institutions?",
    desc: "We partner with colleges and educational institutions to expand our reach and impact. By integrating real-world experience with academic knowledge, we ensure that our interns are well-equipped to meet the demands of today’s dynamic workforce."
  },
  {
    title: "What makes SkillUpward’s internship program unique?",
    desc: "SkillUpward’s internship program is unique due to its commitment to providing a supportive and inclusive environment. We focus on continuous improvement and innovation, tailoring our programs to meet the evolving needs of our interns and partners."
  },
  {
    title: "How can I apply for an internship at SkillUpward?",
    desc: "To apply for an internship at SkillUpward, visit our website and fill out the application form for the desired internship position. You can also reach out to our team for more information on the application process."
  }
];

export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "Mr.Aniket Kashid",
    post: "Chief Executive Officer",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "/images/aniket.jpg",
  },
  {
    id: 2,
    name: "Mr.Himanshu Ghode",
    post: "Chief Operating Officer",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "Mr.Sandesh Jangale",
    post: "Chief Financial Officer",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "/images/image.png",
  },
  {
    id: 4,
    name: "Mr.Pranay Mhatre",
    post: "Chief Technical Officer",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },

  {
    id: 5,
    name: "Ms.Shreya Mankavale",
    post: "Chief Marketing Officer ",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
  {
    id: 6,
    name: "Team",
    post: "Chief Marketing Officer ",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },

]

import React from "react"
import { testimonal } from "../../../dummydata"
import Heading from "../../common/heading/Heading"
import "./style.css"
import Services from "../../Services/Services"

const Testimonal = () => {
  return (
    <>
      <Services />

    </>
  )
}

export default Testimonal
